import React from 'react'
import { useAuth } from './auth-context'
// eslint-disable-next-line @typescript-eslint/camelcase
import { GetMyUser_me } from '../schema/queries/__generated__/GetMyUser'

const UserContext = React.createContext<null | GetMyUser_me>(null)

export const UserProvider: React.FC = props => {
  const authContext = useAuth()
  return (
    <UserContext.Provider value={authContext && authContext.user} {...props} />
  )
}

// eslint-disable-next-line @typescript-eslint/camelcase
export const useUser = (): null | GetMyUser_me => React.useContext(UserContext)
