import matchSorter from 'match-sorter'
import { BuildPlanForm_buildPlan_orders } from '../schema/queries/__generated__/BuildPlanForm'
import { PotentialOrders_buildPlan_potentialOrders } from '../components/BuildPlanForm/__generated__/PotentialOrders'

interface Filters {
  region: string
  status: string
  dosage: string
  orderNumber: string
}

type OrderType =
  | PotentialOrders_buildPlan_potentialOrders
  | BuildPlanForm_buildPlan_orders

const filterByRegion = (orders: OrderType[], filters: Filters): OrderType[] =>
  matchSorter(orders, filters.region, { keys: ['regionCode'] })

const filterByStatus = (orders: OrderType[], filters: Filters): OrderType[] =>
  matchSorter(orders, filters.status, { keys: ['status'] })

const filterByDosage = (orders: OrderType[], filters: Filters): OrderType[] => {
  if (!filters.dosage) return orders

  const dosage = parseFloat(filters.dosage)

  return orders.filter(order => order.doses.some(d => d.dosage === dosage))
}

const filterByOrderNumber = (
  orders: OrderType[],
  filters: Filters
): OrderType[] =>
  matchSorter(orders, filters.orderNumber, { keys: ['orderNumber'] })

export const filterOrders = (
  orders: OrderType[] | PotentialOrders_buildPlan_potentialOrders[],
  filters: Filters
): OrderType[] => {
  let filtered = filterByRegion(orders, filters)
  filtered = filterByStatus(filtered, filters)
  filtered = filterByDosage(filtered, filters)
  return filterByOrderNumber(filtered, filters)
}
