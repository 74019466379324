import gql from 'graphql-tag'

export const REACTOR_CYCLES_FRAGMENT = gql`
  fragment ReactorCyclesFragment on ReactorCycle {
    __typename
    id
    lineItemNumber
    reference
    status
    startDate
    endDate
  }
`

export const REACTOR_COMMITMENTS_FRAGMENT = gql`
  fragment ReactorCommitmentsFragment on ReactorCommitment {
    __typename
    id
    lineItemNumber
    commitmentType
    ampouleMass
    weeklyTarget
    annualCommitment
    commitmentDelivered
    commitmentScheduled
    startDate
    endDate
  }
`

export const REACTOR_DELIVERY_DETAILS_FRAGMENT = gql`
  fragment ReactorDeliveryDetailsFragment on ReactorDeliveryDetails {
    __typename
    id
    lineItemNumber
    deliveryDetailsType
    flightDays
    relativeToFlight
    note
    receiveDays
    relativeToReceive
    receiveTime
  }
`

export const REACTOR_FRAGMENT = gql`
  fragment ReactorFragment on Reactor {
    __typename
    id
    name
    reactorCycles {
      ...ReactorCyclesFragment
    }
    reactorCommitments {
      ...ReactorCommitmentsFragment
    }
    irradiationWeek
    qtyPerContainer
    reactorDeliveryDetails {
      ...ReactorDeliveryDetailsFragment
    }
  }
  ${REACTOR_CYCLES_FRAGMENT}
  ${REACTOR_COMMITMENTS_FRAGMENT}
  ${REACTOR_DELIVERY_DETAILS_FRAGMENT}
`
