import gql from 'graphql-tag'
import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from 'apollo-cache-inmemory'

import introspectionQueryResultData from './fragmentTypes.json'
import {
  ORDER_FILTERS_QUERY,
  CUSTOMER_FILTERS_QUERY,
  USER_FILTERS_QUERY,
  PORTAL_USER_FILTERS_QUERY,
  CANCELLATION_REASON_FILTERS_QUERY,
  CREDIT_REASON_FILTERS_QUERY,
  SAME_DAY_SHIPPING_REASON_FILTERS_QUERY,
  VOID_REASON_FILTERS_QUERY,
  PRODUCT_FILTERS_QUERY,
  SURPLUS_FILTERS_QUERY,
  BUMPABLE_ORDER_FILTERS_QUERY,
  SALES_ORGANIZATION_FILTERS_QUERY,
  JURISDICTION_FILTERS_QUERY,
  PORTAL_ADMIN_PANEL_FILTERS_QUERY,
  TREATMENT_PLAN_FILTERS_QUERY
} from './queries'
import { defaultBumpableCalibrationDate } from '../utils'
import {
  SortDirectionEnum,
  OrderStatusEnum,
  FinanceStatusEnum,
  RegionCodeEnum,
  CustomerBusinessTypeEnum,
} from '../__generated__/globalTypes'
import { SetCustomerFiltersVariables } from './mutations/__generated__/SetCustomerFilters'
import { SetOrderFiltersVariables } from './mutations/__generated__/SetOrderFilters'
import { SetCancellationReasonFiltersVariables } from './mutations/__generated__/SetCancellationReasonFilters'
import { SetCreditReasonFiltersVariables } from './mutations/__generated__/SetCreditReasonFilters'
import { SetSameDayShippingReasonFiltersVariables } from './mutations/__generated__/SetSameDayShippingReasonFilters'
import { SetVoidReasonFiltersVariables } from './mutations/__generated__/SetVoidReasonFilters'
import { SetProductFiltersVariables } from './mutations/__generated__/SetProductFilters'
import { SetUserFiltersVariables } from './mutations/__generated__/SetUserFilters'
import { SetSurplusFiltersVariables } from './mutations/__generated__/SetSurplusFilters'
import { SetBumpableOrderFiltersVariables } from './mutations/__generated__/SetBumpableOrderFilters'
import { SetPortalUserFiltersVariables } from './mutations/__generated__/SetPortalUserFilters'
import { SetSalesOrganizationFiltersVariables } from "./mutations/__generated__/SetSalesOrganizationFilters";
import { SetJurisdictionFiltersVariables } from "./mutations/__generated__/SetJurisdictionFilters";
import { setTreatmentPlanFiltersVariables } from './mutations/__generated__/setTreatmentPlanFilters'

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
})

const typeDefs = gql`
  extend type Query {
    cancellationReasonFilters: CancellationReasonFilters!
    creditReasonFilters: CreditReasonFilters!
    sameDayShippingReasonFilters: SameDayShippingReasonFilters!
    voidReasonFilters: VoidReasonFilters!
    customerFilters: CustomerFilters!
    orderFilters: OrderFilters!
    treatmentPlanFilters: TreatmentPlanFilters!
    productFilters: ProductFilters!
    userFilters: UserFilters!
    portalUserFilters: PortalUserFilters!
    surplusFilters: SurplusFilters!
    bumpableOrderFilters: BumpableOrderFilters!
    salesOrganizationFilters: SalesOrganizationFilters!
    jurisdictionFilters: JurisdictionFilters!
    portalAdminPanelFilters: PortalAdminPanelFilters!
  }

  extend type Mutation {
    setCancellationReasonFilters(
      input: CancellationReasonFiltersInput!
    ): Boolean
    setCreditReasonFilters(input: CreditReasonFiltersInput!): Boolean
    setSameDayShippingReasonFilters(
      input: SameDayShippingReasonFiltersInput!
    ): Boolean
    setVoidReasonFilters(input: VoidReasonFiltersInput!): Boolean
    setCustomerFilters(input: CustomerFiltersInput!): Boolean
    setOrderFilters(input: OrderFiltersInput!): Boolean
    setTreatmentPlanFilters(input: TreatmentPlanFiltersInput!): Boolean
    setProductFilters(input: ProductFiltersInput!): Boolean
    setUserFilters(input: UserFiltersInput!): Boolean
    setSalesOrganizationFilters(input: SalesOrganizationFiltersInput!): Boolean
    setPortalUserFilters(input: PortalUserFiltersInput!): Boolean
    setSurplusFilters(input: SurplusFiltersInput!): Boolean
    setBumpableOrderFilters(input: BumpableOrderFiltersInput!): Boolean
    setJurisdictionFilters(input: JurisdictionFiltersInput!): Boolean
    setPortalAdminPanelFilters(input: PortalAdminPanelFiltersInput!): Boolean
  }

  type BumpableOrderFilters {
    calibrationDate: Date!
    regionCode: RegionCodeEnum!
    dosages: [Float!]!
  }

  input BumpableOrderFiltersInput {
    calibrationDate: Date!
    regionCode: RegionCodeEnum!
    dosages: [Float!]!
  }

  type CancellationReasonFilters {
    page: Int!
    limit: Int!
  }

  input CancellationReasonFiltersInput {
    page: Int!
    limit: Int!
  }

  type CreditReasonFilters {
    page: Int!
    limit: Int!
  }

  input CreditReasonFiltersInput {
    page: Int!
    limit: Int!
  }

  type SameDayShippingReasonFilters {
    page: Int!
    limit: Int!
  }

  input SameDayShippingReasonFiltersInput {
    page: Int!
    limit: Int!
  }

  type VoidReasonFilters {
    page: Int!
    limit: Int!
  }

  input VoidReasonFiltersInput {
    page: Int!
    limit: Int!
  }

  type CustomerFilters {
    search: String!
    withoutContacts: Boolean!
    page: Int!
    limit: Int!
    businessType: [CustomerBusinessTypeEnum!]!
  }

  input CustomerFiltersInput {
    search: String!
    withoutContacts: Boolean!
    page: Int!
    limit: Int!
    businessType: [CustomerBusinessTypeEnum!]!
  }

  type OrderFilters {
    search: String!
    status: OrderStatusEnum!
    financeStatus: FinanceStatusEnum!
    sort: SortInput!
    page: Int!
    limit: Int!
    poBlank: Boolean!
    countries: [String!]!
  }

  input OrderFiltersInput {
    search: String!
    status: OrderStatusEnum!
    financeStatus: FinanceStatusEnum!
    sort: SortInput!
    page: Int!
    limit: Int!
    poBlank: Boolean!
    countries: [String!]!
  }

  type TreatmentPlanFilters {
    search: String!
    sort: SortInput!
    page: Int!
    limit: Int!
    countries: [String!]!
  }
  
  input TreatmentPlanFiltersInput {
    search: String!
    sort: SortInput!
    page: Int!
    limit: Int!
    countries: [String!]!
  }

  type ProductFilters {
    search: String!
    page: Int!
    limit: Int!
  }

  input ProductFiltersInput {
    search: String!
    page: Int!
    limit: Int!
  }

  type SalesOrganizationFilters {
    search: String!
    page: Int!
    limit: Int!
    sort: SortInput!
  }

  input SalesOrganizationFiltersInput {
    page: Int!
    limit: Int!
    sort: SortInput!
  }

  type UserFilters {
    search: String!
    page: Int!
    limit: Int!
    sort: SortInput!
  }

  input UserFiltersInput {
    page: Int!
    limit: Int!
    sort: SortInput!
  }

  type PortalUserFilters {
    search: String!
    page: Int!
    limit: Int!
    sort: SortInput!
  }

  input PortalUserFiltersInput {
    page: Int!
    limit: Int!
    sort: SortInput!
  }

  type SurplusFilters {
    calibrationDate: Date
    regionCode: RegionCodeEnum!
  }

  input SurplusFiltersInput {
    calibrationDate: Date
    regionCode: RegionCodeEnum!
  }

  type JurisdictionFilters {
    search: String!
    page: Int!
    limit: Int!
    sort: SortInput!
  }

  input JurisdictionFiltersInput {
    page: Int!
    limit: Int!
    sort: SortInput!
  }

  type PortalAdminPanelFilters {
    search: String!
    page: Int!
    limit: Int!
    sort: SortInput!
  }

  input PortalAdminPanelFiltersInput {
    page: Int!
    limit: Int!
    sort: SortInput!
  }
`

const resolvers = {
  Mutation: {
    setBumpableOrderFilters: (
      _root: {},
      variables: SetBumpableOrderFiltersVariables,
      { cache }: { cache: InMemoryCache }
    ) => {
      cache.writeQuery({
        query: BUMPABLE_ORDER_FILTERS_QUERY,
        data: {
          bumpableOrderFilters: {
            __typename: 'BumpableOrderFilters',
            ...variables.input,
          },
        },
      })
      return true
    },
    setCancellationReasonFilters: (
      _root: {},
      variables: SetCancellationReasonFiltersVariables,
      { cache }: { cache: InMemoryCache }
    ) => {
      cache.writeQuery({
        query: CANCELLATION_REASON_FILTERS_QUERY,
        data: {
          cancellationReasonFilters: {
            __typename: 'CancellationReasonFilters',
            ...variables.input,
          },
        },
      })
      return true
    },
    setCreditReasonFilters: (
      _root: {},
      variables: SetCreditReasonFiltersVariables,
      { cache }: { cache: InMemoryCache }
    ) => {
      cache.writeQuery({
        query: CREDIT_REASON_FILTERS_QUERY,
        data: {
          creditReasonFilters: {
            __typename: 'CreditReasonFilters',
            ...variables.input,
          },
        },
      })
      return true
    },
    setSameDayShippingReasonFilters: (
      _root: {},
      variables: SetSameDayShippingReasonFiltersVariables,
      { cache }: { cache: InMemoryCache }
    ) => {
      cache.writeQuery({
        query: SAME_DAY_SHIPPING_REASON_FILTERS_QUERY,
        data: {
          sameDayShippingReasonFilters: {
            __typename: 'SameDayShippingReasonFilters',
            ...variables.input,
          },
        },
      })
      return true
    },
    setVoidReasonFilters: (
      _root: {},
      variables: SetVoidReasonFiltersVariables,
      { cache }: { cache: InMemoryCache }
    ) => {
      cache.writeQuery({
        query: VOID_REASON_FILTERS_QUERY,
        data: {
          voidReasonFilters: {
            __typename: 'VoidReasonFilters',
            ...variables.input,
          },
        },
      })
      return true
    },
    setCustomerFilters: (
      _root: {},
      variables: SetCustomerFiltersVariables,
      { cache }: { cache: InMemoryCache }
    ) => {
      cache.writeQuery({
        query: CUSTOMER_FILTERS_QUERY,
        data: {
          customerFilters: {
            __typename: 'CustomerFilters',
            ...variables.input,
          },
        },
      })
      return true
    },
    setOrderFilters: (
      _root: {},
      variables: SetOrderFiltersVariables,
      { cache }: { cache: InMemoryCache }
    ) => {
      cache.writeQuery({
        query: ORDER_FILTERS_QUERY,
        data: {
          orderFilters: {
            __typename: 'OrderFilters',
            ...variables.input,
            sort: {
              __typename: 'SortInput',
              ...variables.input.sort,
            },
          },
        },
      })
      return true
    },
    setTreatmentPlanFilters: (
      _root: {},
      variables: setTreatmentPlanFiltersVariables,
      { cache }: { cache: InMemoryCache }
    ) => {
      cache.writeQuery({
        query: TREATMENT_PLAN_FILTERS_QUERY,
        data: {
          treatmentPlanFilters: {
            __typename: 'TreatmentPlanFilters',
            ...variables.input,
            sort: {
              __typename: 'SortInput',
              ...variables.input.sort,
            },
          },
        },
      })
      return true
    },
    setProductFilters: (
      _root: {},
      variables: SetProductFiltersVariables,
      { cache }: { cache: InMemoryCache }
    ) => {
      cache.writeQuery({
        query: PRODUCT_FILTERS_QUERY,
        data: {
          productFilters: {
            __typename: 'ProductFilters',
            ...variables.input,
          },
        },
      })
      return true
    },
    setUserFilters: (
      _root: {},
      variables: SetUserFiltersVariables,
      { cache }: { cache: InMemoryCache }
    ) => {
      cache.writeQuery({
        query: USER_FILTERS_QUERY,
        data: {
          userFilters: {
            __typename: 'UserFilters',
            ...variables.input,
            sort: {
              __typename: 'SortInput',
              ...variables.input.sort,
            },
          },
        },
      })
      return true
    },
    setPortalUserFilters: (
      _root: {},
      variables: SetPortalUserFiltersVariables,
      { cache }: { cache: InMemoryCache }
    ) => {
      cache.writeQuery({
        query: PORTAL_USER_FILTERS_QUERY,
        data: {
          portalUserFilters: {
            __typename: 'PortalUserFilters',
            ...variables.input,
            sort: {
              __typename: 'SortInput',
              ...variables.input.sort,
            },
          },
        },
      })
      return true
    },
    setSurplusFilters: (
      _root: {},
      variables: SetSurplusFiltersVariables,
      { cache }: { cache: InMemoryCache }
    ) => {
      cache.writeQuery({
        query: SURPLUS_FILTERS_QUERY,
        data: {
          surplusFilters: {
            __typename: 'SurplusFilters',
            ...variables.input,
          },
        },
      })
      return true
    },
    setSalesOrganizationFilters: (
      _root: {},
      variables: SetSalesOrganizationFiltersVariables,
      { cache }: { cache: InMemoryCache }
    ) => {
      cache.writeQuery({
        query: SALES_ORGANIZATION_FILTERS_QUERY,
        data: {
          salesOrganizationFilters: {
            __typename: 'SalesOrganizationFilters',
            ...variables.input,
            sort: {
              __typename: 'SortInput',
              ...variables.input.sort,
            },
          },
        },
      })
      return true
    },
    setJurisdictionFilters: (
      _root: {},
      variables: SetJurisdictionFiltersVariables,
      { cache }: { cache: InMemoryCache }
    ) => {
      cache.writeQuery({
        query: JURISDICTION_FILTERS_QUERY,
        data: {
          jurisdictionFilters: {
            __typename: 'JurisdictionFilters',
            ...variables.input,
            sort: {
              __typename: 'SortInput',
              ...variables.input.sort,
            },
          },
        },
      })
      return true
    },
    setPortalAdminPanelFilters: (
      _root: {},
      variables: SetPortalUserFiltersVariables,
      { cache }: { cache: InMemoryCache }
    ) => {
      cache.writeQuery({
        query: PORTAL_ADMIN_PANEL_FILTERS_QUERY,
        data: {
          portalAdminPanelFilters: {
            __typename: 'PortalAdminPanelFilters',
            ...variables.input,
            sort: {
              __typename: 'SortInput',
              ...variables.input.sort,
            },
          },
        },
      })
      return true
    },
  },
}

const cache = new InMemoryCache({
  freezeResults: true,
  fragmentMatcher,
})

const initialCacheData = {
  bumpableOrderFilters: {
    __typename: 'BumpableOrderFilters',
    regionCode: RegionCodeEnum.ALL,
    calibrationDate: defaultBumpableCalibrationDate(),
    dosages: [],
  },
  cancellationReasonFilters: {
    __typename: 'CancellationReasonFilters',
    page: 1,
    limit: 25,
  },
  creditReasonFilters: {
    __typename: 'CreditReasonFilters',
    page: 1,
    limit: 25,
  },
  customerFilters: {
    __typename: 'CustomerFilters',
    search: '',
    withoutContacts: false,
    page: 1,
    limit: 25,
    businessType:[CustomerBusinessTypeEnum.DirectCustomer],
  },
  orderFilters: {
    __typename: 'OrderFilters',
    search: '',
    status: OrderStatusEnum.ALL,
    financeStatus: FinanceStatusEnum.ALL,
    sort: {
      __typename: 'SortInput',
      column: 'created_at',
      direction: SortDirectionEnum.DESC,
    },
    page: 1,
    limit: 25,
    poBlank: false,
    countries: [],
  },
  treatmentPlanFilters: {
    __typename: 'TreatmentPlanFilters',
    search: '',
    sort: {
      __typename: 'SortInput',
      column: 'created_at',
      direction: SortDirectionEnum.DESC,
    },
    page: 1,
    limit: 25,
    countries: [],
  },
  productFilters: {
    __typename: 'ProductFilters',
    search: '',
    page: 1,
    limit: 25,
  },
  userFilters: {
    __typename: 'UserFilters',
    search: '',
    page: 1,
    limit: 25,
    sort: {
      __typename: 'SortInput',
      column: 'created_at',
      direction: SortDirectionEnum.DESC,
    },
  },
  portalUserFilters: {
    __typename: 'PortalUserFilters',
    search: '',
    page: 1,
    limit: 25,
    sort: {
      __typename: 'SortInput',
      column: 'created_at',
      direction: SortDirectionEnum.DESC,
    },
  },
  surplusFilters: {
    __typename: 'SurplusFilters',
    regionCode: RegionCodeEnum.ALL,
    calibrationDate: null,
  },
  salesOrganizationFilters: {
    __typename: 'SalesOrganizationFilters',
    search: '',
    page: 1,
    limit: 25,
    sort: {
      __typename: 'SortInput',
      column: 'created_at',
      direction: SortDirectionEnum.DESC,
    },
  },
  jurisdictionFilters: {
    __typename: 'JurisdictionFilters',
    search: '',
    page: 1,
    limit: 25,
    sort: {
      __typename: 'SortInput',
      column: 'created_at',
      direction: SortDirectionEnum.ASC,
    },
  },
  portalAdminPanelFilters: {
    __typename: 'PortalAdminPanelFilters',
    search: '',
    page: 1,
    limit: 25,
    sort: {
      __typename: 'SortInput',
      column: 'created_at',
      direction: SortDirectionEnum.ASC,
    },
  },
}

// Initialise the cache with default values
cache.writeData({
  data: initialCacheData,
})

export { typeDefs, resolvers, cache, initialCacheData }
