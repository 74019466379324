import gql from 'graphql-tag'
import { HISTORY_FRAGMENT } from '../fragments'

export const HISTORY_QUERY = gql`
  query HistoryQuery($itemType: VersionItemTypeEnum!, $itemId: ID!) {
    versions(itemType: $itemType, itemId: $itemId) {
      ...History
    }
  }
  ${HISTORY_FRAGMENT}
`
