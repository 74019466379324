import { RoleEnum } from '../__generated__/globalTypes'
import {
  FINANCE_ROLES,
  // MANAGER_ROLES,
  REGIONAL_SALES_MANAGER_ROLES, SALES_ONLY_ROLES,
  SALES_ROLES,
} from '../constants'

export const hasAnyRole = (
  userRoles: RoleEnum[] | null | undefined,
  rolesToCheck: Array<RoleEnum[]> | null
): boolean => {
  if (!userRoles || !rolesToCheck) return false
  const uniqueRoles = new Set(rolesToCheck.flat())
  const uniqueRolesArray = Array.from(uniqueRoles)

  return userRoles.some((role: RoleEnum) => uniqueRolesArray.includes(role))
}

export const hasOnlyRole = (
  userRoles: RoleEnum[] | null | undefined,
  roleToCheck: RoleEnum | null
): boolean => {
  if (!userRoles || !roleToCheck) return false

  return userRoles.every((role: RoleEnum) => role === roleToCheck)
}

// export const hasManagerRole = (userRoles: RoleEnum[] | null): boolean =>
//   hasAnyRole(userRoles, [MANAGER_ROLES])

export const hasFinanceRole = (userRoles: RoleEnum[] | null): boolean =>
  hasAnyRole(userRoles, [FINANCE_ROLES])

export const hasSalesRole = (userRoles: RoleEnum[] | null): boolean =>
  hasAnyRole(userRoles, [SALES_ROLES])

export const hasSalesOnlyRole = (userRoles: RoleEnum[] | null | undefined): boolean =>
  hasAnyRole(userRoles, [SALES_ONLY_ROLES])

export const hasRegionalSalesManagerRole = (
  userRoles: RoleEnum[] | null
): boolean => hasAnyRole(userRoles, [REGIONAL_SALES_MANAGER_ROLES])

export const hasOnlyAJLCoordinatorRole = (
  userRoles: RoleEnum[] | undefined
): boolean => hasOnlyRole(userRoles, RoleEnum.AJL_COORDINATOR)

export const hasSuperAdminRole = (userRoles: RoleEnum[] | undefined): boolean =>
  hasAnyRole(userRoles, [[RoleEnum.SUPER_ADMIN]])