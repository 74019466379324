import styled from '@emotion/styled';
import { Theme as EmotionTheme } from '@emotion/react'

interface CustomTheme {
  colours: {
    primary: string
    primaryLighter: string
    primaryLighterAlt: string
    pink: string
    success: string
    info: string
    warning: string
    danger: string
    tableBorder: string
    inputBorderFocus: string
    greyAlt: string
    lightBlue: string
    darkBlue: string
  }
}

export const theme: CustomTheme = {
  colours: {
    primary: '#00477F',
    primaryLighter: '#2c5697',
    primaryLighterAlt: '#305994',
    pink: '#a277a6',
    success: '#198754',
    info: '#0dcaf0',
    warning: '#ffc107',
    danger: '#dc3545',
    tableBorder: '#ddd',
    inputBorderFocus: '#66afe9',
    greyAlt: '#ccc',
    lightBlue: '#2B85DC',
    darkBlue: '#00477F'
  }
}

// Extend the default theme interface with your custom theme
declare module '@emotion/react' {
  //@ts-ignore
  export interface Theme extends EmotionTheme, CustomTheme {}
}

// Export styled with the extended Theme
export default styled;