export const chunkArray = (array: any[], chunkSize: number) => {
  const results: any[] = []
  const copy = array.slice()

  while (copy.length) {
    results.push(copy.splice(0, chunkSize))
  }

  return results
}

export const transposeArray = (array: any[][]) =>
  array[0].map((_: any, i: number) => array.map(row => row[i]))

export const arrayToColumns = (array: any[], columns: number) => {
  const chunked = chunkArray(array, columns)
  if (!chunked.length) return []

  const transposed = transposeArray(chunked)
  return transposed.map(a => a.filter(Boolean))
}
