import { RoleEnum } from '../__generated__/globalTypes'
import { GetMyUser_me } from '../schema/queries/__generated__/GetMyUser'

interface CheckRoleOptions {
  includeAdmin: boolean
}

export const checkRole = (
  user: GetMyUser_me | null,
  role: RoleEnum | RoleEnum[],
  options: CheckRoleOptions = { includeAdmin: true }
) => {
  if (!user) return false

  const roles = [role].flat()

  if (!options.includeAdmin) {
    const specificRoles = new Set(roles)
    return user.roles.some(r => specificRoles.has(r))
  }

  const allowedRoles = new Set<RoleEnum>([
    RoleEnum.SUPER_ADMIN,
    RoleEnum.ADMIN,
    ...roles,
  ])

  return user.roles.some(r => allowedRoles.has(r))
}

export const hasManagerRole = (user: GetMyUser_me | null): boolean =>
  checkRole(user, RoleEnum.CUSTOMER_SERVICE)

export const hasBuildRole = (user: GetMyUser_me | null): boolean =>
  checkRole(user, RoleEnum.BUILD_PLANNER)
