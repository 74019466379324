import React from 'react'
import { renderToString } from 'react-dom/server'
import styled from '@emotion/styled'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Noty from 'noty'
import 'noty/lib/noty.css'
import 'noty/lib/themes/mint.css'

const NotyBody = styled.div({
  display: 'flex',
  alignItems: 'center',
})

const IconContainer = styled.span({
  fontSize: '24px',
  marginRight: '15px',
  marginLeft: '10px',
})

interface Props {
  text: string
}

const SuccessTemplate: React.FC<Props> = ({ text }) => (
  <>
    <NotyBody className="noty_body noty_has_timeout noty_has_progressbar">
      <IconContainer>
        <FontAwesomeIcon icon="check" />
      </IconContainer>{' '}
      {text}
    </NotyBody>
    <div className="noty_progressbar" />
  </>
)

const ErrorTemplate: React.FC<Props> = ({ text }) => (
  <>
    <NotyBody className="noty_body noty_has_timeout noty_has_progressbar">
      <IconContainer>
        <FontAwesomeIcon icon="times" />
      </IconContainer>{' '}
      {text}
    </NotyBody>
    <div className="noty_progressbar" />
  </>
)

const WarningTemplate: React.FC<Props> = ({ text }) => (
  <>
    <NotyBody className="noty_body noty_has_timeout noty_has_progressbar">
      <IconContainer>
        <FontAwesomeIcon icon="exclamation" />
      </IconContainer>{' '}
      {text}
    </NotyBody>
    <div className="noty_progressbar" />
  </>
)

const InfoTemplate: React.FC<Props> = ({ text }) => (
  <>
    <NotyBody className="noty_body noty_has_timeout noty_has_progressbar">
      <IconContainer>
        <FontAwesomeIcon icon="info" />
      </IconContainer>{' '}
      {text}
    </NotyBody>
    <div className="noty_progressbar" />
  </>
)

Noty.overrideDefaults({
  callbacks: {
    onTemplate: function() {
      if (this.options.type === 'success') {
        this.barDom.innerHTML = renderToString(
          <SuccessTemplate text={this.options.text} />
        )
      } else if (this.options.type === 'error') {
        this.barDom.innerHTML = renderToString(
          <ErrorTemplate text={this.options.text} />
        )
      } else if (this.options.type === 'warning') {
        this.barDom.innerHTML = renderToString(
          <WarningTemplate text={this.options.text} />
        )
      } else {
        this.barDom.innerHTML = renderToString(
          <InfoTemplate text={this.options.text} />
        )
      }
    },
  },
})

export class Notification {
  static get defaultOptions(): Noty.Options {
    return {
      timeout: 3000,
      layout: 'topRight',
      // theme: 'bootstrap-v4',
    }
  }

  static error(text: string, options: Noty.Options = {}): void {
    this.show(text, {
      ...this.defaultOptions,
      timeout: false,
      type: 'error',
      ...options,
    })
  }

  static success(text: string, options: Noty.Options = {}): void {
    this.show(text, {
      ...this.defaultOptions,
      type: 'success',
      ...options,
    })
  }

  static warning(text: string, options: Noty.Options = {}): void {
    this.show(text, {
      ...this.defaultOptions,
      type: 'warning',
      ...options,
    })
  }

  static info(text: string, options: Noty.Options = {}): void {
    this.show(text, {
      ...this.defaultOptions,
      type: 'info',
      ...options,
    })
  }

  static show(text: string, options: Noty.Options = {}): void {
    new Noty({
      ...this.defaultOptions,
      text: this.cleanText(text),
      ...options,
    }).show()
  }

  static cleanText(text: string): string {
    // Do any required text cleaning here
    // For now, we only need to remove GraphQL content
    return text.replace('GraphQL error: ', '')
  }
}
