export const kebabToSnake = (string: string) =>
  string.replace(/(-\w)/g, m => '_' + m[1]).toLowerCase()

export const snakeToKebab = (string: string) =>
  string.replace(/(_\w)/g, m => '-' + m[1]).toLowerCase()

export const toKebabCase = (string: string) => {
  return (
    string &&
    string
      .replace(/([A-Z])([A-Z])/g, '$1-$2')
      .replace(/([a-z])([A-Z])/g, '$1-$2')
      .replace(/[\s_]+/g, '-')
      .toLowerCase()
  )
}
