import React, { useEffect } from 'react'
import gql from 'graphql-tag'
import Cookie from 'js-cookie'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { ApolloQueryResult } from 'apollo-client'
import { Spinner } from '../components/spinner'
// eslint-disable-next-line @typescript-eslint/camelcase
import { Login, LoginVariables } from './__generated__/Login'
import { GET_ME } from '../schema/queries'
import {
  GetMyUser,
  GetMyUser_me,
} from '../schema/queries/__generated__/GetMyUser'
import { Notification } from '../utils'
import { CSRF_TOKEN } from '../constants'

// TODO [#546]: Once you have a known type/interface for the user object, please update
// this file and replace all 'GetMyUser_person' occurances, with the User type.
interface AuthContext {
  // eslint-disable-next-line @typescript-eslint/camelcase
  user: GetMyUser_me | null
  login: (
    email: string,
    password: string
  ) => Promise<ApolloQueryResult<GetMyUser> | null>
  logout: () => void
  loading: boolean
}

const AuthContext = React.createContext<AuthContext | null>(null)

const LOGIN = gql`
  mutation Login($input: LoginInput!) {
    login(input: $input) {
      id
      firstName
      lastName
      name
      email
      timeZone
      roles
    }
  }
`

export const AuthProvider: React.FC = props => {
  // code for pre-loading the user's information if we have their token in localStorage goes here
  const { client, data, loading, error, refetch } = useQuery<GetMyUser>(
    GET_ME,
    {
      fetchPolicy: 'network-only',
    }
  )

  useEffect(() => {
    if (error) Notification.error(error.message)
  }, [error])

  const [
    loginMutation,
    { loading: loginLoading, error: loginError },
  ] = useMutation<Login, LoginVariables>(LOGIN, {
    fetchPolicy: 'no-cache',
  })

  useEffect(() => {
    if (loginError) Notification.error(loginError.message)
  }, [loginError])

  const login = async (
    email: string,
    password: string
  ): Promise<ApolloQueryResult<GetMyUser> | null> => {
    // make a login request & then refetch the 'me' query
    const { data, errors } = await loginMutation({
      variables: { input: { email, password } },
    })

    if (errors) {
      errors.forEach(e => Notification.error(e.message))
      return null
    }

    if (!data || !data.login) return null

    return refetch()
  }

  const logout = async (): Promise<void> => {
    try {
      // Clear the CSRF token cookie
      Cookie.set(CSRF_TOKEN, '')
      // Clear the apollo store, and then refetch the user's data (which will fail)
      await client.clearStore()
      refetch()
    } catch (error) {
      console.log(error.message)
    }
  }

  // 🚨 this is the important bit.
  // Normally your provider components render the context provider with a value.
  // But we post-pone rendering any of the children until after we've determined
  // whether or not we have a user token and if we do, then we render a spinner
  // while we go retrieve that user's information.
  // NB: we want to let auth errors through, so that the client can handle store
  // resetting etc.
  // if (error) return <h2>{`${error}`}</h2>

  if (loading) return <Spinner />

  // note, I'm not bothering to optimize this `value` with React.useMemo here
  // because this is the top-most component rendered in our app and it will very
  // rarely re-render/cause a performance problem.
  return (
    <AuthContext.Provider
      value={{
        user: data ? data.me : null,
        login,
        logout,
        loading: loginLoading,
      }}
      {...props}
    />
  )
}

export const useAuth = (): AuthContext | null => React.useContext(AuthContext)
