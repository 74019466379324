import gql from 'graphql-tag'

export const FULL_PRODUCT_FRAGMENT = gql`
  fragment FullProduct on Product {
    id
    productType
    region {
      id
      name
    }
    itemNumber
    itemDescription
    dosage
    itemType
    itemUsageType
    productUse
    additionalInfo
    sapMaterialNumber
    freight
    freightType
    createdAt
    updatedAt
    archivedAt
  }
`
