import gql from 'graphql-tag'
import { CONTACT_FRAGMENT } from './ContactFragment'
import { DELIVERY_CHARGE_FRAGMENT } from './DeliveryChargeFragment'
import { TREATMENT_FRAGMENT, ACCESSORY_FRAGMENT, SIMPLICITY_FRAGMENT } from './ProductOrderFragments'

import { SAP_BILLING_DETAIL_FRAGMENT } from './SapBillingDetailFragments'
import { HISTORY_FRAGMENT } from './HistoryFragments'

export const FULL_ORDER_FRAGMENT = gql`
  fragment FullOrder on Order {
    actualShipDate
    accessories {
      ...Accessory
    }
    address {
      id
      city
      country
      freightTerms
      incoterms
      incotermsLocation
      incotermsYear
      line1
      line2
      line3
      nordionShipToId
      primary
      state
      zip
      contacts(notifiable: true) {
        ...ContactFragment
      }
    }
    attachments {
      id
      filename
      extension
      url
    }
    buildPlan {
      id
      buildNumber
      status
    }
    calibrationDate
    cancellationReason {
      id
      code
      description
    }
    carrier
    contacts {
      ...ContactFragment
    }
    creditReason {
      id
      code
      description
      type
    }
    creditType
    creditTotalAmount
    sapCreditMemoNumber
    debitTotalAmount
    debitCurrencyCode
    debitReason {
      id
      code
      description
      type
    }
    creditCurrencyCode
    rebillCurrencyCode
    customer {
      id
      financeId
      distributorId
      distributor {
        id
        name
      }
      name
      nordionAccountNumber
      quote {
        id
        reference
      }
      nrTsNumbers
      sendOrdersToSap
      inheritAddressesFromDistributor
      businessType
    }
    deliveryCharges {
      ...DeliveryChargeFragment
    }
    deliveryDate
    deliveryTime
    dockDate
    dockTime
    duplicateOrderNumbers
    financeStatus
    id
    nordionRef
    shippingLink
    orderDate
    orderNumber
    orderType
    patientRef
    poNumber
    poNumberAfterShipped
    regionCode
    sameDayShipping
    sameDayShippingReason {
      id
      code
      description
    }
    shipDate
    shippingNote
    mnxShippingNote
    status
    treatments {
      ...Treatment
    }
    treatmentDate
    treatmentTime
    simplicities {
      ...Simplicity
    }
    updatedAt
    usingSurplus
    usingBwxtSurplus
    usingMnxSurplus
    usingMnxCaSurplus
    usingMnxReservedSurplusInventory
    waybillNumber
    statusBeforeCancelling
    sourceType
    invoiceNotes
    billingBlock
    sapBillingDetail {
      ...SapBillingDetailFragment
    }
    createdAt
    sapSalesOrderNumber
    invoiceNumber
    invoiceTotalAmount
    invoiceCurrencyCode
    invoiceNotesCredit
    invoiceNotesDebit
    internalComments
    mnxDeliveryContactName
    mnxDeliveryContactPhoneNumber
    notForSap
    simplicityPerPlan
    rebillSimplicityPerPlan
    deliveryChargeMessage
    addressChangedAt
    sapTotalPrice
    sapCurrencyCode
    sapPriceType
    versions {
      ...History
    }
    mnxCreateOrderError
    sapBillingCode
    sapBillingCodeDebit
    versions {
      ...History
    }
    distributor {
      id
      name
    }
    distributorAddress {
      id
      city
      country
      freightTerms
      incoterms
      incotermsLocation
      incotermsYear
      line1
      line2
      line3
      nordionShipToId
      primary
      state
      zip
      contacts(notifiable: true) {
        ...ContactFragment
      }
    }
    alertData
    specialDeal
    treatingPhysicianId
    treatingPhysicianName
    isCountryRestricted
    restrictedAccessories
    treatmentIntent
    skippedSalesOrganization
    sapRouting
    sapDebitMemoNumber
    s4SalesOrderCreditCheckErrorMessage
    s4OrderCreationFailedReason
    s4OrderUpdateFailedReason
    s4CreditCheckFailedReason
    poMissing
  }
  ${CONTACT_FRAGMENT}
  ${TREATMENT_FRAGMENT}
  ${ACCESSORY_FRAGMENT}
  ${SIMPLICITY_FRAGMENT}
  ${SAP_BILLING_DETAIL_FRAGMENT}
  ${HISTORY_FRAGMENT}
  ${DELIVERY_CHARGE_FRAGMENT}
`
