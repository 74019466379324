import gql from 'graphql-tag'

export const CONTACT_FRAGMENT = gql`
  fragment ContactFragment on Contact {
    __typename
    id
    email
    name
    phone
    receiveOrderNotification
    receiveShippingNotification
  }
`
