import gql from 'graphql-tag'

export const PURCHASE_ORDER_FRAGMENT = gql`
  fragment PurchaseOrderFragment on PurchaseOrder {
    id
    reference
    price
    startDate
    endDate
  }
`
