import React from 'react'
import { AuthProvider } from '../context/auth-context'
import { UserProvider } from '../context/user-context'
import { OrderModalProvider } from './OrderModalContext'

export const AppProviders: React.FC = ({ children }) => (
  <AuthProvider>
    <UserProvider>
      <OrderModalProvider>{children}</OrderModalProvider>
    </UserProvider>
  </AuthProvider>
)

export * from './OrderModalContext'
