import Cookie from 'js-cookie'
import { ApolloClient } from 'apollo-client'
import { HttpLink } from 'apollo-link-http'
import { onError } from 'apollo-link-error'
import { setContext } from 'apollo-link-context'
import { ApolloLink } from 'apollo-link'
import ActionCable from 'actioncable'
import ActionCableLink from 'graphql-ruby-client/subscriptions/ActionCableLink'
import { typeDefs, resolvers, cache, initialCacheData } from './client-schema'
import { CSRF_TOKEN } from '../constants'

const apiUrl = `${process.env.REACT_APP_API_URL}/top/cable`
const cableUrl = apiUrl.replace('http', 'ws')
const cable = ActionCable.createConsumer(cableUrl)

// @ts-ignore
const hasSubscriptionOperation = ({ query: { definitions } }): boolean => {
  return definitions.some(
    // @ts-ignore
    ({ kind, operation }) =>
      kind === 'OperationDefinition' && operation === 'subscription'
  )
}

const httpLink = new HttpLink({
  uri: `${process.env.REACT_APP_API_URL}/top/graphql`,
  credentials: 'include',
  fetchOptions: {
    credentials: 'include',
  },
})

const csrfLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      'X-CSRF-Token': Cookie.get(CSRF_TOKEN),
    },
  }
})

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  let checkUserIsAuthenticated = false
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path, extensions }) => {
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
          locations
        )}, Path: ${path}`
      )

      if (
        extensions &&
        extensions.code &&
        extensions.code === 'AUTHENTICATION_ERROR'
      ) {
        checkUserIsAuthenticated = true
      }
    });
    if (checkUserIsAuthenticated) {
      Cookie.set(CSRF_TOKEN, '')
      client.clearStore()
      //Refreshing the page to re-direct the flow to login page as logout funtion is not accessible here
      window.location.reload()
    }
  }

  if (networkError) console.log('[Network error]:', networkError)
});

const afterwareLink = new ApolloLink((operation, forward) => {
  if (!forward) return null
  return forward(operation).map(mappedResponse => {
    const context = operation.getContext()
    const { response } = context
    const headers = response && response.headers

    if (headers) {
      const csrfToken = headers.get(CSRF_TOKEN)
      if (csrfToken) {
        Cookie.set(CSRF_TOKEN, csrfToken)
      }
    }

    return mappedResponse
  })
})

const subscriptionLink = ApolloLink.split(
  hasSubscriptionOperation,
  new ActionCableLink({ cable }),
  httpLink
)

export const client = new ApolloClient({
  link: ApolloLink.from([errorLink, csrfLink, afterwareLink, subscriptionLink]),
  typeDefs,
  resolvers,
  cache,
  assumeImmutableResults: true,
})

client.onClearStore(async () => cache.writeData({ data: initialCacheData }))
client.onResetStore(async () => cache.writeData({ data: initialCacheData }))
