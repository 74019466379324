import gql from 'graphql-tag'

export const GET_ME = gql`
  query GetMyUser {
    me {
      id
      firstName
      lastName
      name
      email
      ... on TopUser {
        timeZone
        roles
      }
    }
  }
`

export const GET_USERS_FOR_SELECT = gql`
  query UsersForSelect(
    $role: RoleEnum
    $sort: SortInput
    $page: Int
    $limit: Int
  ) {
    users(role: $role, sort: $sort, page: $page, limit: $limit) {
      totalPages
      items {
        id
        name
      }
    }
  }
`

export const GET_USERS = gql`
  query GetUsers(
    $search: String
    $role: RoleEnum
    $sort: SortInput
    $page: Int
    $limit: Int
  ) {
    users(
      search: $search
      role: $role
      sort: $sort
      page: $page
      limit: $limit
    ) {
      totalPages
      items {
        id
        firstName
        lastName
        suspended
        email
        ... on TopUser {
          roles
        }
      }
    }
  }
`

export const GET_USER = gql`
  query GetUser($id: ID!) {
    user(id: $id) {
      id
      firstName
      lastName
      email
      name
      ... on TopUser {
        roles
      }
    }
  }
`

export const GET_UNASSIGNED_USERS = gql`
  query GetUnassignedUsers {
    unassignedUsers(limit: 999) {
      items {
        id
        name
      }
    }
  }
`

export const USER_SEARCH = gql`
  query UserSearch($q: String!) {
    userSearch(q: $q) {
      id
      email
      name
      firstName
      lastName
    }
  }
`

export const EDIT_USER_QUERY = gql`
  query EditUserQuery($id: ID!) {
    user(id: $id) {
      id
      firstName
      lastName
      email
      name
      suspended
      unconfirmedEmail
      confirmationToken
      resetPasswordToken
      ... on TopUser {
        roles
        manager {
          id
          name
        }
        subordinates {
          id
          name
        }
      }
    }
  }
`
