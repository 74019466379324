export const CURRENCY_CODES = [
  'EUR',
  'USD',
  'CAD',
  'GBP',
  'CHF',
  'CNY',
  'CZK',
  'DKK',
  'NOK',
  'NTD',
  'PLN',
  'SAR',
  'SEK',
  'SGD',
  'TRY',
  'VND',
  'WON',
  'ZAR'
]

export const CURRENCY_CODE_OPTIONS = CURRENCY_CODES.map(currencyCode => ({ value: currencyCode, label: currencyCode }))