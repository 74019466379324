export class Range {
  values: number[]

  constructor(start: number, end: number, step: number = 1) {
    this.values = Array.from({
      length: (step + (end - start)) / step
    }, (_, i) => start + (i * step))
  }

  static new(start: number, end: number, step: number = 1): Array<number> {
    const range = new Range(start, end, step)
    return range.values
  }
}
