import {
  convertToRaw,
  ContentState,
  RawDraftEntity,
  RawDraftContentState,
} from 'draft-js'

interface MentionTag {
  id: string
  name: string
}

interface EntityRange {
  key: number
  length: number
  offset: number
}

type EntitiesType = EntityRange[] | null

const getIndicesOf = (
  searchStr: string,
  str: string,
  caseSensitive?: boolean
): number[] => {
  let tempStr = str
  let tempSearchStr = searchStr
  const searchStrLen = tempSearchStr.length
  if (searchStrLen === 0) {
    return []
  }
  let startIndex = 0
  let index: number
  const indices: number[] = []
  if (!caseSensitive) {
    tempStr = tempStr.toLowerCase()
    tempSearchStr = tempSearchStr.toLowerCase()
  }

  while ((index = tempStr.indexOf(tempSearchStr, startIndex)) > -1) {
    indices.push(index)
    startIndex = index + searchStrLen
  }
  return indices
}

const getEntityRanges = (
  text: string,
  mentionName: string,
  mentionKey: number
): EntitiesType => {
  const indices = getIndicesOf(mentionName, text)
  if (indices.length > 0) {
    return indices.map(offset => ({
      key: mentionKey,
      length: mentionName.length,
      offset,
    }))
  }

  return null
}

export const createMentionEntities = (
  text: string,
  tags: MentionTag[]
): RawDraftContentState => {
  const rawContent = convertToRaw(ContentState.createFromText(`${text} `))
  const rawState: RawDraftEntity[] = tags.map(tag => ({
    type: 'mention',
    mutability: 'SEGMENTED',
    data: {
      mention: tag,
    },
  }))

  rawContent.entityMap = rawState.reduce((object, tag, index) => {
    // @ts-ignore
    object[index] = tag
    return object
  }, {})

  rawContent.blocks = rawContent.blocks.map(block => {
    const ranges: EntityRange[] = []

    tags.forEach((tag, index) => {
      const entityRanges = getEntityRanges(block.text, tag.name, index)
      if (entityRanges) {
        ranges.push(...entityRanges)
      }
    })

    return { ...block, entityRanges: ranges }
  })

  return rawContent
}
