import { RegionCodeEnum } from '../__generated__/globalTypes'
import { Option } from '../components/Inputs'
import COUNTRY_DATA from '../countries.json'

interface Country {
  code: string
  name: string
}

const countryToOption = (country: Country): Option => ({
  value: country.code,
  label: country.name,
})

const ROWOption: Option = {
  label: 'Rest of the World region',
  value: RegionCodeEnum.ROW,
}

export const generateCountryOptions = (
  priority?: string[],
  includeROW?: boolean
): Option[] => {
  if (priority) {
    const priorityOptions: Option[] = []
    const otherOptions: Option[] = []

    COUNTRY_DATA.forEach(country => {
      if (priority.includes(country.code)) {
        priorityOptions.push(countryToOption(country))
      } else {
        otherOptions.push(countryToOption(country))
      }
    })

    if (includeROW) {
      return [...priorityOptions, ROWOption, ...otherOptions]
    }
    return [...priorityOptions, ...otherOptions]
  } else {
    return COUNTRY_DATA.map(countryToOption)
  }
}
