import { SapReasonCodeEnum } from '../__generated__/globalTypes'

export const SAP_REASON_CODE_OPTIONS = [
  { value: SapReasonCodeEnum.E14, label: 'E14 - Order not shipped in time' },
  { value: SapReasonCodeEnum.G01, label: 'G01 - Entry error C/S' },
  { value: SapReasonCodeEnum.G08, label: 'G08 - No reason' },
  { value: SapReasonCodeEnum.J11, label: 'J11 - Case cancelled' },
  { value: SapReasonCodeEnum.J10, label: 'J10 - Credit and rebill' },
  { value: SapReasonCodeEnum.J21, label: 'J21 - Ship error replace' },
  { value: SapReasonCodeEnum.OTH, label: 'OTH - Other' },
  { value: SapReasonCodeEnum.D07, label: 'D07 - Price Adjustment' },
  { value: SapReasonCodeEnum.T01, label: 'T01 - TheraSphere Returns' },
]
