import { getPreviousDayOfWeek } from "./getPreviousDayOfWeek"
import { getNextDayOfWeek } from "./getNextDayOfWeek"

export const getCalibrationDate = (date: Date): Date => {
  switch (date.getDay()) {
    case 0:
      return date
    case 1:
    case 2:
      return getPreviousDayOfWeek(date, 0)
    default:
      return getNextDayOfWeek(date, 0)
  }
}
