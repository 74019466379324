import { InventoryTransferStatusEnum, InventoryTransferOrderStatusEnum, ITOSurplusStatusEnum } from '../__generated__/globalTypes'

export const INVENTORY_TRANSFER_CANCELLABLE_STATUSES = [
  InventoryTransferStatusEnum.DRAFT,
  InventoryTransferStatusEnum.CONFIMED_BY_BWXT,
  InventoryTransferStatusEnum.REQUESTED,
]

export const INVENTORY_TRANSFER_ORDER_CANCELLABLE_STATUSES = [
  InventoryTransferOrderStatusEnum.BUILD_PENDING,
  InventoryTransferOrderStatusEnum.PENDING_TRANSFER,
]

export const INVENTORY_TRANSFER_CONFIRM_CANCELLATION_STATUSES = [
  InventoryTransferStatusEnum.DRAFT,
  InventoryTransferStatusEnum.CONFIMED_BY_BWXT,
  InventoryTransferStatusEnum.REQUESTED,
  InventoryTransferStatusEnum.REQUEST_CANCELLED,
]

export const INVENTORY_TRANSFER_ORDER_CONFIRM_CANCELLATION_STATUSES = [
  InventoryTransferOrderStatusEnum.CANCELLING,
]

export const INVENTORY_TRANSFER_ORDER_NOT_BUILT_STATUSES = [
  InventoryTransferOrderStatusEnum.BUILD_PENDING,
]

export const INVENTORY_TRANSFER_ORDER_PENDING_TRANSFER_STATUSES = [
  InventoryTransferOrderStatusEnum.BUILD_PENDING,
]

export const INVENTORY_TRANSFER_SURPLUS_TRANSITION_TO_BUILD_PENDING_STATUSES = [
  ITOSurplusStatusEnum.pending,
]

export const INVENTORY_TRANSFER_ORDER_REINSTATEABLE_STATUSES = [
  InventoryTransferOrderStatusEnum.BUILD_PENDING,
  InventoryTransferOrderStatusEnum.PENDING_TRANSFER,
]
