import gql from 'graphql-tag'

export const GET_REGIONS_QUERY = gql`
  query GetRegions($all: Boolean) {
    regions(all: $all) {
      id
      name
      code
    }
  }
`
