import gql from 'graphql-tag'

export const QUOTE_FRAGMENT = gql`
  fragment QuoteFragment on Quote {
    id
    reference
    price
    startDate
    endDate
  }
`
