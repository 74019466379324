import gql from 'graphql-tag'

export const SAP_BILLING_DETAIL_FRAGMENT = gql`
  fragment SapBillingDetailFragment on SapBillingDetail {
    id
    poRequired
    sapSoldTo
    sapPayer
    sapBillTo
    invoiceNotes
    sapSalesOrg
    sapBillingCode
  }
`
