import gql from 'graphql-tag'

export const DELIVERY_CHARGES_QUERY = gql`
  query DeliveryCharges {
    deliveryCharges {
      id
      key
      label
    }
  }
`
