import gql from 'graphql-tag'

export const GET_TS360_USERS = gql`
  query GetTS360Users(
    $search: String
    $sort: SortInput
    $page: Int
    $limit: Int
  ) {
      ts360Users(
        search: $search, sort: $sort, page: $page, limit: $limit
      ) {
          currentPage
          firstPage
          lastPage
          nextPage
          previousPage
          size
          totalItems
          totalPages
          items {
              archivedAt
              email
              firstName
              id
              isTreatingPhysician
              lastName
              ts360Id
          }
      }
  }
`