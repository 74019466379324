import { Option } from "../components/Inputs"

export const generateTimeArray = (): Option[] => {
  const timeArray: Option[] = [];
  for (let hour = 0; hour < 24; hour++) {
    const timeLabel = `${hour.toString().padStart(2, '0')}:00`;
    const timeValue = `${hour.toString().padStart(2, '0')}:00`;
    timeArray.push({ label: timeLabel, value: timeValue });
  }
  return timeArray;
}
