import gql from 'graphql-tag'

export const COMMENT_FRAGMENT = gql`
  fragment Comment on Comment {
    id
    createdAt
    user {
      id
      name
    }
    content
    rawContent
  }
`
