export const sortObjectsByDate = (array: any[], key: string) => {
  const keys = key.split('.')
  const len = keys.length

  const sorted = [...array].sort((a, b) => {
    var i = 0
    while (i < len) {
      a = a[keys[i]]
      b = b[keys[i]]
      i++
    }

    return new Date(a).getTime() - new Date(b).getTime()
  })

  return sorted
}
