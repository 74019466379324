import gql from 'graphql-tag'

export const AMPOULE_SHEDULES_INDEX_QUERY = gql`
  query AmpouleSchedulesIndex(
    $calibrationDateFrom: String!
    $calibrationDateTo: String!
    $page: Int
    $limit: Int
  ) {
    ampouleSchedules(
      calibrationDateFrom: $calibrationDateFrom
      calibrationDateTo: $calibrationDateTo
      page: $page
      limit: $limit
    ) {
      items {
        __typename
        date
        ampouleSchedulingStatus
      }
    }
  }
`

export const BUILD_PLANS_FOR_ACTUAL_ALLOCATION_QUERY = gql`
  query BuildPlansForActualAllocationIndex(
    $regionCode: RegionCodeEnum
    $calibrationDate: Date
    $page: Int
    $limit: Int
  ) {
    buildPlans(
      regionCode: $regionCode
      calibrationDate: $calibrationDate
      page: $page
      limit: $limit
    ) {
      totalPages
      items {
        __typename
        id
        ampoules{
          name
          mass
          massTransfer
          sa
          reactorName
        }
        buildNumber
        runDate
        runNumber
        status
        openStatus
        averageMassTransfer
        averageSa
        calVialsSa
      }
    }
  }
`
