import {
  DemandTabQuery_buildPlan,
} from '../components/BuildPlanForm/__generated__/DemandTabQuery'

import {
  AllocatedOrders_buildPlan_allocatedDoses,
} from '../components/BuildPlanForm/__generated__/AllocatedOrders'

// Find total mass of order based on dosage, quantity and vial sa
const totalMass = (dosage, quantity, sa) => {
  let mass = Math.round(dosage / sa)
  // NB: 3 GBq has a lower limit of 21mg
  if (dosage === 3)
    mass = Math.max(mass, 21)
  return mass * quantity

}

export const calculateMass = (
  doses: AllocatedOrders_buildPlan_allocatedDoses[],
  buildPlan: DemandTabQuery_buildPlan
): number => {
  return doses.reduce((sum, { quantity, dosage }) => {

    const total = totalMass(dosage, quantity, buildPlan.saForCalculation)

    return sum + total
  }, 0)
}
