import gql from 'graphql-tag'
import {BUILD_PLAN_FORM_FRAGMENT, FULL_INVENTORY_TRANSFER_FRAGMENT} from '../fragments'

export const BUILD_PLANS_INDEX_QUERY = gql`
  query BuildPlansIndex(
    $regionCode: RegionCodeEnum
    $calibrationDate: Date
    $page: Int
    $limit: Int
  ) {
    buildPlans(
      regionCode: $regionCode
      calibrationDate: $calibrationDate
      page: $page
      limit: $limit
    ) {
      totalPages
      items {
        __typename
        id
        buildNumber
        runNumber
        runDate
        allocatedDoses {
          quantity
        }
        surplusBuilt
        status
        openStatus
      }
    }
  }
`

export const BUILD_PLAN_FORM_QUERY = gql`
  query BuildPlanForm($id: ID!) {
    buildPlan(id: $id) {
      ...BuildPlanForm
    }
  }
  ${BUILD_PLAN_FORM_FRAGMENT}
`

export const BUILD_PLAN_CONTROL_ROOM_QUERY = gql`
  query BuildPlanControlRoom($id: ID!) {
    buildPlan(id: $id) {
      __typename
      id
      buildNumber
      calibrationDate
      runNumber
      runDate
      status
    }
  }
`

export const BUILD_PLAN_FBA_QUERY = gql`
  query BuildPlanFba($buildPlanId: ID!, $regionCode: RegionCodeEnum!) {
    buildPlanSummary(buildPlanId: $buildPlanId, regionCode: $regionCode) {
      dosage
      quantity
      bpQuantity
    }
  }
`

export const DEMAND_AT_RISK_QUERY = gql`
  query DemandAtRiskQuery($id: ID!) {
    buildPlan(id: $id) {
      __typename
      id
      status
      hasDemandAtRisk
      demandAtRisk {
        ca
        dosage
        row
        us
      }
      itoDemandAtRisk {
        ca
        dosage
        row
        us
      }
      inventoryTransfer {
        ...FullInventoryTransfer
      }
      orders {
        id
        orderNumber
        shipDate
        doses {
          regionCode
          dosage
          quantity
        }
      }
    }
  }
  ${FULL_INVENTORY_TRANSFER_FRAGMENT}
`

export const BUILD_PLAN_POTENTIAL_ORDERS_QUERY = gql`
  query BuildPlanPotentialOrders($id: ID!) {
    buildPlan(id: $id) {
      __typename
      id
      potentialOrders {
        id
        orderNumber
        regionCode
        shipDate
        status
        doses {
          regionCode
          quantity
          dosage
        }
      }
    }
  }
`

export const POTENTIAL_ORDERS_QUERY = gql`
  query potentialOrdersQuery(
    $calibrationDate: Date!
    $filterBy: PotentialOrdersFilterTypeInput
    $page: Int
    $limit: Int
    $sort: SortInput
  ) {
    potentialOrders(
      calibrationDate: $calibrationDate
      filterBy: $filterBy
      page: $page
      limit: $limit
      sort: $sort
    ){
      totalPages
      potentialOrders 
      {
        __typename
        id
        orderNumber
        regionCode
        shipDate
        status
        doses {
          regionCode
          quantity
          dosage
        }
      }
    }
  }
`

export const ALLOCATED_DOSES_QUERY = gql`
  query allocatedDosesQuery(
    $id: ID!
    $filterBy: PotentialOrdersFilterTypeInput
    $page: Int
    $limit: Int
    $sort: SortInput
  ) {
    allocatedDoses(
    id: $id
    filterBy: $filterBy
    page: $page
    limit: $limit
    sort: $sort
    ) {
      totalPages
      allocatedDoses
      {
        __typename
        id
        orderNumber
        regionCode
        shipDate
        status
        doses {
          regionCode
          quantity
          dosage
        }
      }
    }
  }
`

export const BUILD_PLAN_ORDERS_QUERY = gql`
  query buildPlanOrdersQuery(
    $id: ID!
  ) {
    buildPlanOrders(
      id: $id
    ){ 
      __typename
      id
      orderNumber
      regionCode
      shipDate
      status
      doses {
        regionCode
        quantity
        dosage
      }
    }
  }
`

