import { getPreviousDayOfWeek } from './getPreviousDayOfWeek'
import { getNextDayOfWeek } from './getNextDayOfWeek'

/**
 * NB: Finds the nearest cal date for bumpable orders.
 * In reality we can't have any built doses until the day after the first run,
 * i.e. Thursday.
 */
export const defaultBumpableCalibrationDate = (): Date => {
  const today = new Date()

  if (today.getDay() < 4) {
    return getPreviousDayOfWeek(today, 0)
  }

  return getNextDayOfWeek(today, 0)
}
