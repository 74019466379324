/** @jsx jsx */
import React from 'react'
import { jsx } from '@emotion/core'
import logo from '../images/therasphere-logo.png'
import { Spinner as ReactStrapSpinner } from 'reactstrap'

export const Spinner: React.FC = props => (
  <div
    css={{
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#fff',
    }}
  >
    <img src={logo} alt="loading" aria-label="loading" {...props} />
    <ReactStrapSpinner
      type="grow"
      color="primary"
      style={{ marginTop: '2rem', width: '4rem', height: '4rem' }}
    />
  </div>
)
