import gql from 'graphql-tag'

export const GET_COMMENTS_QUERY = gql`
  query GetComments($type: String!, $id: String!) {
    comments(type: $type, id: $id) {
      id
      createdAt
      user {
        id
        email
        name
        firstName
      }
      content
      rawContent
    }
  }
`
