import React from 'react'
import ReactDOM from 'react-dom'
import { ApolloProvider } from '@apollo/react-hooks'
import { ThemeProvider } from '@emotion/react'

import { App } from '../src/app'
import { AppProviders } from '../src/context/index'

import { client } from './schema/apollo-client'

import 'bootstrap/dist/js/bootstrap.bundle.min';
import './styles/application.scss'

import { theme } from './theme'
import { Maintenance } from './components/maintenance'

if (process.env.REACT_APP_MAINTENANCE_MODE === 'true') {
  ReactDOM.render(<Maintenance />, document.getElementById('root'))
} else {
  ReactDOM.render(
    <ApolloProvider client={client}>
      <AppProviders>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </AppProviders>
    </ApolloProvider>,
    document.getElementById('root')
  )
}
