import gql from 'graphql-tag'

export const GET_SALES_ORGANIZATIONS = gql`
  query GetSalesOrganizations(
    $search: String
    $sort: SortInput
    $page: Int
    $limit: Int
  ) {
    salesOrganizations(
      search: $search
      sort: $sort
      page: $page
      limit: $limit
    ) {
      totalPages
      items {
        id
        code
        countries
        currencies
        sapRouting
      }
    }
  }
`

export const GET_SALES_ORGANIZATION = gql`
  query GetSalesOrganization($id: ID!) {
    salesOrganization(id: $id) {
      id
      code
      countries
      currencies
      sapRouting
      creditCheckNotificationEmails {
        creditCheckIncomplete {
          from
          to
          cc
          bcc
        }
        creditCheckFailed {
          from
          to
          cc
          bcc
        }
        creditWarningReceived {
          from
          to
          cc
          bcc
        }
        creditBlockRemoved {
          from
          to
          cc
          bcc
        }
        orderVoidedAfterCreditCheck {
          from
          to
          cc
          bcc
        }
      }
      freightAtHeaderLevel
    }
  }
`
export const GET_ALL_SALES_ORGANIZATIONS = gql`
  query GetAllSalesOrganizations {
    allSalesOrganizations {
      items {
        id
        code
        countries
        currencies
        sapRouting
      }
    }
  }
`

export const GET_SALES_ORGANIZATION_CURRENCIES = gql`
  query SalesOrganizationCurrencies($code: String!) {
    salesOrganizationCurrencies(code: $code) {
      items {
        currency
      }
    }
  }
`

export const EDIT_SALES_ORGANIZATION = gql`
  query EditSalesOrganization($id: ID!) {
    salesOrganization(id: $id) {
      id
      code
      countries
      currencies
      sapRouting
      creditCheckNotificationEmails {
        creditCheckIncomplete {
          from
          to
          cc
          bcc
        }
        creditCheckFailed {
          from
          to
          cc
          bcc
        }
        creditWarningReceived {
          from
          to
          cc
          bcc
        }
        creditBlockRemoved {
          from
          to
          cc
          bcc
        }
        orderVoidedAfterCreditCheck {
          from
          to
          cc
          bcc
        }
      }
      freightAtHeaderLevel
    }
  }
`