import gql from 'graphql-tag'

export const CHECK_CONFIRMATION_STATUS = gql`
  query ConfirmationStatus($token: String!) {
    confirmationStatus(token: $token) {
      pendingReconfirmation
      alreadyConfirmed
    }
  }
`
