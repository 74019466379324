import { BUILD_PLAN_WARNING_ORDER_STATUSES } from '../constants'
import { GetOrder_order } from '../schema/queries/__generated__/GetOrder'
import { BuildPlanStatusEnum } from '../__generated__/globalTypes'
import { OrdersIndex_orders_items } from '../schema/queries/__generated__/OrdersIndex'

type Order = GetOrder_order | OrdersIndex_orders_items

export const orderNeedsAccepting = (order: Order): boolean => {
  if (!BUILD_PLAN_WARNING_ORDER_STATUSES.includes(order.status)) return false

  return order.buildPlan?.status === BuildPlanStatusEnum.PENDING
}
