import {RoleEnum} from '../__generated__/globalTypes'

export const ADMIN_ROLES = [RoleEnum.SUPER_ADMIN, RoleEnum.ADMIN]

export const BUILD_PLANNER_ROLES = [...ADMIN_ROLES, RoleEnum.BUILD_PLANNER]

export const FINANCE_ROLES = [RoleEnum.FINANCE]

export const MANAGER_ROLES = [...ADMIN_ROLES, RoleEnum.CUSTOMER_SERVICE]

export const READ_ONLY_ROLES = [RoleEnum.READ_ONLY]

export const REGIONAL_SALES_MANAGER_ROLES = [
  ...ADMIN_ROLES,
  RoleEnum.REGIONAL_SALES_MANAGER,
]

export const SALES_ROLES = [...ADMIN_ROLES, RoleEnum.SALES]

export const SALES_ONLY_ROLES = [RoleEnum.SALES, RoleEnum.REGIONAL_SALES_MANAGER]

export const TEAM_MEMBER_ROLES = [...ADMIN_ROLES, RoleEnum.CUSTOMER_SERVICE, RoleEnum.REGIONAL_SALES_MANAGER, RoleEnum.SALES]

export const AJL_COORDINATOR_ROLES = [RoleEnum.SUPER_ADMIN, RoleEnum.AJL_COORDINATOR]

export const AJL_VIEW_ROLES = [RoleEnum.SUPER_ADMIN, RoleEnum.ADMIN, RoleEnum.AJL_COORDINATOR]
