import gql from 'graphql-tag'
import { REACTOR_COMMITMENTS_FRAGMENT, REACTOR_CYCLES_FRAGMENT, REACTOR_DELIVERY_DETAILS_FRAGMENT } from '../fragments'

export const REACTOR_CENTER_INDEX_QUERY = gql`
  query ReactorCenterIndex($page: Int, $limit: Int) {
    reactors(page: $page, limit: $limit) {
      items {
        __typename
        id
        name
        currentReactorCommitments {
          ...ReactorCommitmentsFragment
        }
      }
    }
  }
  ${REACTOR_COMMITMENTS_FRAGMENT}
`

export const REACTOR_CENTERS_QUERY = gql`
  query ReactorCenters($page: Int, $limit: Int) {
    reactors(page: $page, limit: $limit) {
      items {
        __typename
        id
        name
        irradiationWeek
        reactorCycles {
          ...ReactorCyclesFragment
        }
        reactorDeliveryDetails {
          ...ReactorDeliveryDetailsFragment
        }
      }
    }
  }
  ${REACTOR_COMMITMENTS_FRAGMENT}
  ${REACTOR_DELIVERY_DETAILS_FRAGMENT}
  ${REACTOR_CYCLES_FRAGMENT}
`

export const GET_REACTOR = gql`
  query GetReactor($id: ID!) {
    reactor(id: $id) {
      __typename
      id
      name
      reactorCycles {
        ...ReactorCyclesFragment
      }
      reactorCommitments {
        ...ReactorCommitmentsFragment
      }
      irradiationWeek
      qtyPerContainer
      reactorDeliveryDetails {
        ...ReactorDeliveryDetailsFragment
      }
    }
  }
  ${REACTOR_CYCLES_FRAGMENT}
  ${REACTOR_COMMITMENTS_FRAGMENT}
  ${REACTOR_DELIVERY_DETAILS_FRAGMENT}
`

export const EDIT_REACTOR = gql`
  query EditReactor($id: ID!) {
    reactor(id: $id) {
      __typename
      id
      name
      reactorCycles {
        ...ReactorCyclesFragment
      }
      reactorCommitments {
        ...ReactorCommitmentsFragment
      }
      irradiationWeek
      qtyPerContainer
      reactorDeliveryDetails {
        ...ReactorDeliveryDetailsFragment
      }
    }
  }
  ${REACTOR_CYCLES_FRAGMENT}
  ${REACTOR_COMMITMENTS_FRAGMENT}
  ${REACTOR_DELIVERY_DETAILS_FRAGMENT}
`

export const REACTOR_CENTER_TIMELINE_QUERY = gql`
  query CummulativeOperatingSchedule($calibrationStartDate: String!, $calibrationEndDate: String!) {
    cummulativeOperatingSchedule(calibrationStartDate: $calibrationStartDate, calibrationEndDate: $calibrationEndDate) {
      reactor {
        id
        name
      }
      timelineData {
        calibrationDate
        status
        irradiationDate
        flightDate
      }
    }
  }
`