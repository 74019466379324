import gql from 'graphql-tag'
import { FULL_INVENTORY_TRANSFER_FRAGMENT } from '../../schema/fragments/InventoryTransferFragments'

// TODO [#547]: create new fragment / query for demand at risk
export const BUILD_PLAN_FORM_FRAGMENT = gql`
  fragment BuildPlanForm on BuildPlan {
    __typename
    id
    buildNumber
    calibrationDate
    runNumber
    runDate
    status
    openStatus
    tmosUpdated
    itoEnabled
    itoSubmitted
    inventoryTransfer {
      ...FullInventoryTransfer
    }
    mnxSpareShipDate
    mnxSpareExpectedDeliveryDate
    calVialsSa
    saForCalculation
    nordionLotNumber
    availableMass
    totalMass
    usedMass
    minDispensingLimit
    maxDispensingLimit
    allDoses {
      dosage
      ca
      row
      us
    }
    backupDoses {
      quantity
      dosage
      regionCode
    }
    qcDoses {
      quantity
      dosage
    }
    vials {
      backup
      demand
      spare
      mnxSpare
    }
    mnxSpareDoses {
      quantity
      dosage
      regionCode
    }
    orders {
      __typename
      id
      orderNumber
      regionCode
      shipDate
      status
      doses {
        regionCode
        quantity
        dosage
      }
    }
    ampoules {
      __typename
      id
      name
      mass
      massTransfer
      sa
      reactorId
      reactorName
    }
    spareDoses {
      quantity
      dosage
      regionCode
    }
    summaryAtSubmit {
      dosage
      regions {
        regionCode
        typeQuantities {
          backup
          clinical
          commercial
          spare
          mnxSpare
        }
      }
    }
  }
  ${ FULL_INVENTORY_TRANSFER_FRAGMENT }
`

export const BUILD_PLAN_ORDER_CHANGE_FRAGMENT = gql`
  fragment BuildPlanOrderChange on BuildPlan {
    __typename
    id
    availableMass
    usedMass
    totalMass
    allocatedDoses {
      quantity
      dosage
      regionCode
    }
    orders {
      __typename
      id
      orderNumber
      regionCode
      shipDate
      status
      doses {
        regionCode
        quantity
        dosage
      }
    }
    potentialOrders {
      __typename
      id
      orderNumber
      regionCode
      shipDate
      status
      doses {
        regionCode
        quantity
        dosage
      }
    }
    vials {
      demand
      backup
      mnxSpare
    }
    mnxSpareDoses {
      quantity
      dosage
      regionCode
    }
    backupDoses {
      quantity
      dosage
      regionCode
    }
    qcDoses {
      quantity
      dosage
    }
  }
`

export const BUILD_PLAN_BACKUP_DOSE_CHANGE_FRAGMENT = gql`
  fragment BuildPlanBackupDoseChange on BuildPlan {
    __typename
    id
    availableMass
    usedMass
    totalMass
    backupDoses {
      quantity
      dosage
      regionCode
    }
    vials {
      backup
    }
    qcDoses {
      quantity
      dosage
    }
  }
`

export const BUILD_PLAN_SPARE_DOSE_CHANGE_FRAGMENT = gql`
  fragment BuildPlanSpareDoseChange on BuildPlan {
    __typename
    id
    availableMass
    usedMass
    totalMass
    spareDoses {
      quantity
      dosage
      regionCode
    }
    vials {
      spare
    }
    qcDoses {
      quantity
      dosage
    }
  }
`

export const BUILD_PLAN_MNX_SPARE_DOSE_CHANGE_FRAGMENT = gql`
  fragment BuildPlanMnxSpareDoseChange on BuildPlan {
    __typename
    id
    availableMass
    usedMass
    totalMass
    mnxSpareDoses {
      quantity
      dosage
      regionCode
    }
    vials {
      mnxSpare
    }
    summary {
      dosage
      regions {
        regionCode
        typeQuantities {
          backup
          clinical
          commercial
          spare
          mnxSpare
        }
      }
    }
    qcDoses {
      quantity
      dosage
    }
  }
`


export const BUILD_PLAN_ORDERS_WITH_POTENTIAL_FRAGMENT = gql`
  fragment BuildPlanOrdersWithPotential on BuildPlan {
    __typename
    id
    orders {
      __typename
      id
      orderNumber
      regionCode
      shipDate
      status
      doses {
        regionCode
        quantity
        dosage
      }
    }
    potentialOrders {
      __typename
      id
      orderNumber
      regionCode
      shipDate
      status
      doses {
        regionCode
        quantity
        dosage
      }
    }
  }
`