import gql from 'graphql-tag'

export const SAP_BILLING_DETAILS_QUERY = gql`
  query SapBillingDetailsIndex {
    sapBillingDetails {
      items {
        sapSalesOrg
      }
    }
  }
`