export const getLabelByValue = (
  value: string | undefined | null,
  collection: Array<{ value: string, label: string }>
): string => {
  if (value === null || value === undefined) return ''

  let obj = collection.find(o => o.value === value.toUpperCase()) as Object

  return obj['label']
}
