import { STATES } from './states'
import { OrderStatusEnum } from '../__generated__/globalTypes'

export interface OrderPublicAttrs {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any
}

export const ORDER_CUTOFF_HOURS_ENV = process.env.REACT_APP_ORDER_CUT_OFF_HOUR
export const ORDER_CUTOFF_MINS_ENV = process.env.REACT_APP_ORDER_CUT_OFF_MIN
export const ORDER_CUT_OFF_HOURS = ORDER_CUTOFF_HOURS_ENV
  ? parseInt(ORDER_CUTOFF_HOURS_ENV)
  : 20
export const ORDER_CUT_OFF_MINS = ORDER_CUTOFF_MINS_ENV
  ? parseInt(ORDER_CUTOFF_MINS_ENV)
  : 7

export const ORDER_PUBLIC_ATTRS: OrderPublicAttrs = {
  orderDate: 'Order date',
  treatmentDate: 'Treatment date',
  treatmentTime: 'Treatment time',
  shipDate: 'Ship date',
  dockDate: 'Dock Date at Distributor',
  dockTime: 'Dock Time at Distributor',
  deliveryDate: 'Delivery Date at Destination',
  deliveryTime: 'Delivery Time at Destination',
  actualShipDate: 'Actual ship date',
  treatingPhysicianName: 'Treating Physician',
  treatmentIntent: 'Treatment Intent',
  patientRef: 'Treatment Reference',
  poNumber: 'PO number',
  shippingLink: 'Tracking link',
  nordionRef: 'Shipping reference',
  waybillNumber: 'Waybill no.',
  carrier: 'Carrier',
  billingBlock: 'Billing Block',
  simplicityPerPlan: 'Simplicity Software',
  sapBillingCode: 'SAP Billing Code',
}

export const CANCELLABLE_STATUSES = [
  OrderStatusEnum.ACCEPTED,
  OrderStatusEnum.BUILD_PENDING,
  OrderStatusEnum.BUILT,
  OrderStatusEnum.REJECTED,
]
export const VOIDABLE_STATUSES = [STATES.DRAFT, STATES.NEW, STATES.RESERVED]
export const REINSTATEABLE_STATUSES = [
  OrderStatusEnum.ACCEPTED,
  OrderStatusEnum.BUILD_PENDING,
  OrderStatusEnum.BUILT,
]
export const VOID_STATUSES = [STATES.DRAFT, STATES.NEW, STATES.VOID]
export const CANCELLATION_REASON_STATUSES = [
  STATES.CANCELLING,
  STATES.CANCELLED,
  STATES.VOID,
]

export const ATTRIBUTES_EDITABLE_STATUSES = [
  OrderStatusEnum.DRAFT,
  OrderStatusEnum.NEW,
  OrderStatusEnum.RESERVED
]

export const DELIVERY_CHARGES_EDITABLE_STATUSES = [
  OrderStatusEnum.BUILT,
  OrderStatusEnum.BUILD_PENDING,
  OrderStatusEnum.ACCEPTED,
  OrderStatusEnum.RELEASED,
  OrderStatusEnum.DRAFT,
  OrderStatusEnum.NEW,
  OrderStatusEnum.RESERVED,
]

export const OPTIONAL_ATTRIBUTES_EDITABLE_STATUSES = [
  OrderStatusEnum.DRAFT,
  OrderStatusEnum.NEW,
  OrderStatusEnum.RESERVED,
  OrderStatusEnum.RELEASED,
  OrderStatusEnum.ACCEPTED,
  OrderStatusEnum.BUILD_PENDING,
  OrderStatusEnum.BUILT,
  OrderStatusEnum.SHIPPED,
]

export const EDITABLE_STATUSES = [
  OrderStatusEnum.DRAFT,
  OrderStatusEnum.NEW,
  OrderStatusEnum.RELEASED,
  OrderStatusEnum.ACCEPTED,
  OrderStatusEnum.BUILT,
  OrderStatusEnum.SHIPPED,
]

export const FREE_REASON_EDITABLE_STATUSES = [
  OrderStatusEnum.DRAFT,
  OrderStatusEnum.NEW,
  OrderStatusEnum.RESERVED
]

export const SIMPLICITY_FREE_REASON_EDITABLE_STATUSES = [
  OrderStatusEnum.DRAFT,
  OrderStatusEnum.NEW,
  OrderStatusEnum.RELEASED,
  OrderStatusEnum.ACCEPTED,
  OrderStatusEnum.BUILT,
]

export const SURPLUS_EDITABLE_STATUSES = [
  OrderStatusEnum.DRAFT,
  OrderStatusEnum.NEW,
  OrderStatusEnum.RELEASED,
  OrderStatusEnum.RESERVED,
  OrderStatusEnum.ACCEPTED,
]

export const RESERVED_IN_TRANSIT_EDITABLE_STATUSES = [
  OrderStatusEnum.DRAFT,
  OrderStatusEnum.NEW,
  OrderStatusEnum.RESERVED,
]

export const BUILD_PLAN_WARNING_ORDER_STATUSES = [
  OrderStatusEnum.DRAFT,
  OrderStatusEnum.NEW,
  OrderStatusEnum.RELEASED,
]

export const SOURCE_TYPE_EDITABLE_STATUSES = [
  OrderStatusEnum.DRAFT,
  OrderStatusEnum.NEW,
  OrderStatusEnum.RESERVED,
  OrderStatusEnum.RELEASED,
  OrderStatusEnum.ACCEPTED,
  OrderStatusEnum.REJECTED,
  OrderStatusEnum.BUILD_PENDING,
  OrderStatusEnum.BUILT,
  OrderStatusEnum.SHIPPED,
  OrderStatusEnum.VOID,
  OrderStatusEnum.CANCELLING,
  OrderStatusEnum.CANCELLED,
]

export const BUILD_SHIPPED_ORDER_STATUSES = [
  OrderStatusEnum.BUILT,
  OrderStatusEnum.SHIPPED,
]

export const SAP_BILLING_CODE_EDITABLE_STATUSES = [
  OrderStatusEnum.DRAFT,
  OrderStatusEnum.NEW,
  OrderStatusEnum.RESERVED,
  OrderStatusEnum.RELEASED,
  OrderStatusEnum.ACCEPTED,
  OrderStatusEnum.REJECTED,
  OrderStatusEnum.BUILD_PENDING,
  OrderStatusEnum.BUILT,
]

export const SAME_DAY_ORDER_SURPLUS_VALIDATION_STATUSES = [
  OrderStatusEnum.DRAFT,
  OrderStatusEnum.NEW,
  OrderStatusEnum.RESERVED
]