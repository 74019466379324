import { OrderStatusEnum } from '../__generated__/globalTypes'

interface States {
  [key: string]: string
}

export const STATES: States = {
  BUMPED: 'bumped',
  ...OrderStatusEnum,
}
