interface HasTypeName {
  __typename: string
}

export function removeTypename<T extends HasTypeName>({
  __typename,
  ...attrs
}: T): Omit<T, '__typename'> {
  return attrs
}
