import gql from 'graphql-tag'
import { REASON_FRAGMENT } from '../fragments'

export const GET_REASONS_QUERY = gql`
  query GetReasons(
    $type: ReasonTypeEnum!
    $page: Int
    $limit: Int
    $showArchived: Boolean
  ) {
    reasons(
      type: $type
      page: $page
      limit: $limit
      showArchived: $showArchived
    ) {
      totalPages
      items {
        archivedAt
        code
        description
        customerFacingDescription
        showInPortal
        id
        createdAt
        sapCode
      }
    }
  }
`

export const GET_REASON_QUERY = gql`
  query GetReason($id: ID!) {
    reason(id: $id) {
      ...ReasonFragment
    }
  }
  ${REASON_FRAGMENT}
`
