import React, { useState } from 'react'

interface OrderModalContext {
  modalOpen: boolean
  openModal: () => void
  closeModal: () => void
}

const OrderModalContext = React.createContext<null | OrderModalContext>(null)

export const OrderModalProvider: React.FC = props => {
  const [modalOpen, setModalOpen] = useState(false)
  const openModal = (): void => setModalOpen(true)
  const closeModal = (): void => setModalOpen(false)

  return (
    <OrderModalContext.Provider
      value={{
        modalOpen,
        openModal,
        closeModal,
      }}
      {...props}
    />
  )
}

export const useOrderModal = (): null | OrderModalContext =>
  React.useContext(OrderModalContext)
