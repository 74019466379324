import React from 'react'
import { useUser } from './context/user-context'
import { Spinner } from './components/spinner'
import { Maintenance } from './components/maintenance'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faArrowLeft,
  faCaretUp,
  faCaretDown,
  faCheck,
  faCog,
  faComments,
  faTimes,
  faInfo,
  faInfoCircle,
  faExclamation,
  faPlus,
  faMinus,
  faChevronDown,
  faChevronUp,
  faUser,
  faFilePdf,
  faFileExcel,
  faFileAlt,
  faExclamationTriangle,
  faClock,
  faSquare,
  faDollarSign,
  faThumbsUp,
  faThumbsDown,
  faComment,
  faUndo,
  faArrowDown,
  faExternalLinkAlt,
  faFileDownload,
  faTrash,
  faCheckCircle,
  faEdit
} from '@fortawesome/free-solid-svg-icons'

library.add(
  faTrash,
  faFileDownload,
  faArrowLeft,
  faCaretUp,
  faCaretDown,
  faCheck,
  faCog,
  faComments,
  faTimes,
  faInfo,
  faInfoCircle,
  faExclamation,
  faPlus,
  faMinus,
  faChevronDown,
  faChevronUp,
  faUser,
  faFilePdf,
  faFileExcel,
  faFileAlt,
  faExclamationTriangle,
  faClock,
  faSquare,
  faDollarSign,
  faThumbsUp,
  faThumbsDown,
  faComment,
  faUndo,
  faArrowDown,
  faCheckCircle,
  faExternalLinkAlt,
  faEdit
)

const loadAuthenticatedApp = (): Promise<
  typeof import('./authenticated-app')
> => import('./authenticated-app')
const AuthenticatedApp = React.lazy(loadAuthenticatedApp)
const UnauthenticatedApp = React.lazy(() => import('./unauthenticated-app'))

export const App: React.FC = () => {
  const user = useUser()
  // pre-load the authenticated side in the background while the user's
  // filling out the login form.
  React.useEffect(() => {
    loadAuthenticatedApp()
  }, [])

  return (
    <React.Suspense fallback={<Spinner />}>
      {user ? <AuthenticatedApp /> : <UnauthenticatedApp />}
    </React.Suspense>
  )
}
