import gql from 'graphql-tag'
import { RAMLACTIVITYLIMITCHECK_FRAGMENT } from '../fragments/RamlCheckFragments'

export const RAML_CHECK_ACTIVITY_QUERY = gql`
  query RamlActivities($id: ID!) {
    ramlActivities(id: $id) {
      items {
        checkStatus,
        site {
          id,
          siteReference,
          distributorSite
        },
        license {
          licenseReference,
          status,
          checkAt,
          expiresAt
        },
        activityLimit {
          ordersActivity,
          dailyActivity {
            ...RamlActivityLimitCheck
          },
          weeklyActivity {
            ...RamlActivityLimitCheck
          },
          monthlyActivity {
            ...RamlActivityLimitCheck
          },
          yearlyActivity {
            ...RamlActivityLimitCheck
          },
          maximumIndividualDoseActivityAtDeliveryLimit
        },
        checkInitiated,
        errors,
        warnings
      }
    }
  }
  ${RAMLACTIVITYLIMITCHECK_FRAGMENT}
`
