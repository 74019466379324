import gql from 'graphql-tag'
import { POSTAL_ADDRESS_FRAGMENT } from '../fragments/AddressFragments'

export const GET_PORTAL_USERS = gql`
  query GetPortalUsers(
    $search: String
    $sort: SortInput
    $page: Int
    $limit: Int
  ) {
    portalUsers(search: $search, sort: $sort, page: $page, limit: $limit) {
      currentPage
      firstPage
      lastPage
      nextPage
      previousPage
      size
      totalItems
      totalPages
      items {
        id
        firstName
        lastName
        email
        suspended
        canCreateOrders
        nordionShipToIds
        customer {
          id
          name
        }
        signInCount
        currentSignInAt
      }
    }
  }
`

export const GET_PORTAL_USER = gql`
  query GetPortalUser($id: ID!) {
    portalUser(id: $id) {
      id
      firstName
      lastName
      email
      name
      profession
      otherProfession
      canCreateOrders
      addressIds
      customer {
        id
        name
        region {
          name
        }
        nrTsNumbers
        addresses {
          id
          nordionShipToId
          ...PostalAddressFragment
        }
      }
    }
  }
  ${POSTAL_ADDRESS_FRAGMENT}
`

export const CUSTOMER_SEARCH_QUERY = gql`
  query CustomerSearchQuery(
    $search: String
    $withoutContacts: Boolean
    $page: Int
    $limit: Int
  ) {
    customers(
      search: $search
      withoutContacts: $withoutContacts
      page: $page
      limit: $limit
    ) {
      totalPages
      items {
        id
        name
        nrTsNumbers
      }
    }
  }
`
