import gql from 'graphql-tag'

export const REASON_FRAGMENT = gql`
  fragment ReasonFragment on Reason {
    id
    code
    description
    customerFacingDescription
    showInPortal
    archivedAt
    createdAt
    updatedAt
    sapCode
    creditBillingCode
    rebillBillingCode
  }
`
