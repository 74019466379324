import gql from 'graphql-tag'

export const BUMPABLE_ORDER_FILTERS_QUERY = gql`
  query GetBumpableOrderFilters {
    bumpableOrderFilters @client {
      calibrationDate
      regionCode
      dosages
    }
  }
`

export const CANCELLATION_REASON_FILTERS_QUERY = gql`
  query GetCancellationReasonFilters {
    cancellationReasonFilters @client {
      page
      limit
    }
  }
`

export const CREDIT_REASON_FILTERS_QUERY = gql`
  query GetCreditReasonFilters {
    creditReasonFilters @client {
      page
      limit
    }
  }
`

export const SAME_DAY_SHIPPING_REASON_FILTERS_QUERY = gql`
  query GetSameDayShippingReasonFilters {
    sameDayShippingReasonFilters @client {
      page
      limit
    }
  }
`

export const VOID_REASON_FILTERS_QUERY = gql`
  query GetVoidReasonFilters {
    voidReasonFilters @client {
      page
      limit
    }
  }
`

export const ORDER_FILTERS_QUERY = gql`
  query GetOrderFilters {
    orderFilters @client {
      search
      status
      financeStatus
      sort {
        column
        direction
      }
      page
      limit
      poBlank
      countries
    }
  }
`

export const TREATMENT_PLAN_FILTERS_QUERY = gql`
  query GetTreatmentPlanFilters {
    treatmentPlanFilters @client {
      search
      sort {
        column
        direction
      }
      page
      limit
      countries
    }
  }
`

export const PRODUCT_FILTERS_QUERY = gql`
  query GetProductFilters {
    productFilters @client {
      search
      page
      limit
    }
  }
`

export const CUSTOMER_FILTERS_QUERY = gql`
  query GetCustomerFilters {
    customerFilters @client {
      search
      withoutContacts
      page
      limit
      businessType
    }
  }
`

export const USER_FILTERS_QUERY = gql`
  query GetUserFilters {
    userFilters @client {
      search
      page
      limit
      sort {
        column
        direction
      }
    }
  }
`

export const PORTAL_USER_FILTERS_QUERY = gql`
  query GetPortalUserFilters {
    portalUserFilters @client {
      search
      page
      limit
      sort {
        column
        direction
      }
    }
  }
`

export const SURPLUS_FILTERS_QUERY = gql`
  query GetSurplusFilters {
    surplusFilters @client {
      regionCode
      calibrationDate
    }
  }
`

export const SALES_ORGANIZATION_FILTERS_QUERY = gql`
  query GetSalesOrganizationFilters {
    salesOrganizationFilters @client {
      search
      page
      limit
      sort {
        column
        direction
      }
    }
  }
`

export const JURISDICTION_FILTERS_QUERY = gql`
  query GetJurisdictionFilters {
    jurisdictionFilters @client {
      search
      page
      limit
      sort {
        column
        direction
      }
    }
  }
`

export const PORTAL_ADMIN_PANEL_FILTERS_QUERY = gql`
  query GetPortalAdminPanelFilters {
    portalAdminPanelFilters @client {
      search
      page
      limit
      sort {
        column
        direction
      }
    }
  }
`
