import gql from 'graphql-tag'

export const FULL_TEAM_MEMBER_FRAGMENT = gql`
  fragment FullTeamMemberFragment on TeamMember {
    id
    createdAt
    updatedAt
    firstName
    lastName
    email
    profession
    otherProfession
    npi
    customerId
    portalUserId
    isPortalUser
    treatingPhysician
  }
`
