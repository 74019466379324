import gql from 'graphql-tag'
import { CONTACT_FRAGMENT } from './ContactFragment'
import { DELIVERY_CHARGE_FRAGMENT } from './DeliveryChargeFragment'

export const POSTAL_ADDRESS_FRAGMENT = gql`
  fragment PostalAddressFragment on Address {
    city
    country
    line1
    line2
    line3
    state
    zip
  }
`

export const FULL_ADDRESS_FRAGMENT = gql`
  fragment AddressFragment on Address {
    id
    ...PostalAddressFragment
    freightTerms
    incoterms
    incotermsLocation
    incotermsYear
    nordionShipToId
    calibrationDataSheetRequired
    gpAddressCode
    shippingNote
    mnxShippingNote
    contacts {
      ...ContactFragment
    }
    adminSetIds
    primary
    attnTo
    attnTo2
    sapShipTo
    mnxCustomer
    mnxEligibleForRush
    mnxDepotPriority
    deliveryCharges {
      ...DeliveryChargeFragment
    }
    customerFriendlyName
    timezone
  }
  ${POSTAL_ADDRESS_FRAGMENT}
  ${CONTACT_FRAGMENT}
  ${DELIVERY_CHARGE_FRAGMENT}
`

export const ADDRESS_SELECT_FRAGMENT = gql`
  fragment AddressSelect on Address {
    id
    primary
    nordionShipToId
    ...PostalAddressFragment
    shippingNote
    mnxShippingNote
    attnTo
    attnTo2
    deliveryCharges {
      ...DeliveryChargeFragment
    }
  }
  ${DELIVERY_CHARGE_FRAGMENT}
  ${POSTAL_ADDRESS_FRAGMENT}
`
