import gql from 'graphql-tag'

export const AMPOULE_ALLOCATION_PLAN_QUERY = gql`
  query AmpouleAllocationPlans(
    $calibrationDate: String!
    $page: Int
    $limit: Int
  ) {
    ampouleAllocationPlans(
      calibrationDate: $calibrationDate
      page: $page
      limit: $limit
    ) {
      items {
        id
        calibrationDate
        runDate
        runNumber
        default
        isInsurance
      }
    }
  }
`
